import { match as categoryType } from "../../../src/params/categoryType.ts";
import { match as l_about } from "../../../src/params/l_about.ts";
import { match as l_booking } from "../../../src/params/l_booking.ts";
import { match as l_contact } from "../../../src/params/l_contact.ts";
import { match as l_faq } from "../../../src/params/l_faq.ts";
import { match as l_privacy_policy } from "../../../src/params/l_privacy_policy.ts";
import { match as l_review } from "../../../src/params/l_review.ts";
import { match as l_terms_and_conditions } from "../../../src/params/l_terms_and_conditions.ts";
import { match as langCode } from "../../../src/params/langCode.ts";

export const matchers = { categoryType, l_about, l_booking, l_contact, l_faq, l_privacy_policy, l_review, l_terms_and_conditions, langCode };