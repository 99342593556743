export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [2];

export const dictionary = {
		"/[lang=langCode]": [4,[2]],
		"/[lang=langCode]/author": [14,[2]],
		"/[lang=langCode]/success": [15,[2]],
		"/[lang=langCode]/[l_terms_and_conditions=l_terms_and_conditions]": [13,[2]],
		"/[lang=langCode]/[l_review=l_review]": [12,[2]],
		"/[lang=langCode]/[l_privacy_policy=l_privacy_policy]": [11,[2]],
		"/[lang=langCode]/[l_faq=l_faq]": [10,[2]],
		"/[lang=langCode]/[l_contact=l_contact]": [9,[2]],
		"/[lang=langCode]/[l_booking=l_booking]": [8,[2]],
		"/[lang=langCode]/[l_about=l_about]": [7,[2]],
		"/[lang=langCode]/[category=categoryType]": [5,[2,3]],
		"/[lang=langCode]/[category=categoryType]/[slug]": [~6,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';